import { render, staticRenderFns } from "./VirtualRoomList.vue?vue&type=template&id=227effb6&scoped=true"
import script from "./VirtualRoomList.vue?vue&type=script&lang=js"
export * from "./VirtualRoomList.vue?vue&type=script&lang=js"
import style0 from "./VirtualRoomList.vue?vue&type=style&index=0&id=227effb6&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "227effb6",
  null
  
)

export default component.exports