<template>
  <v-app>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí Test Set</h1>
    </div>
    <b-card no-body>
      <b-tabs pills card v-model="currentStep">
        <b-tab
          title="Test Practice"
          active
          title-link-class="btn btn-light-info"
        >
          <TestPracticeList :product_categories="product_category_tags" />
        </b-tab>
        <b-tab title="Virtual Room" title-link-class="btn btn-light-info">
          <VirtualRoomList :product_categories="product_category_tags" />
        </b-tab>
      </b-tabs>
    </b-card>
  </v-app>
</template>

<script>
import ApiService from "@/service/api.service";
import VirtualRoomList from "./VirtualRoomList";
import TestPracticeList from "./TestPracticeList";
export default {
  name: "TestSetTab",
  components: { TestPracticeList, VirtualRoomList },
  data() {
    return {
      currentStep: 0,
      product_category_tags: [],
    };
  },
  created() {
    this.getAllProductCategory();
  },
  methods: {
    async getAllProductCategory() {
      let vm = this;
      await ApiService.get("prep-app/product-category/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.product_category_tags = response.data;
        }
      });
    },
  },
};
</script>

<style scoped></style>
